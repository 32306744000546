import login from './loginSlice';
import user from './userSlice';
import events from './eventsSlice';
import advantages from './advantagesSlice';
import partners from './partnersSlice';
import alert from './alertSlice';
import discounts from './discountSlice';
import questions from './questionsSlice';

export default { login, user, events, advantages, partners, alert, discounts, questions };
