const Mark = () => {
	// return (
	// 	<div
	// 		id='marks'
	// 		className='bg-primary px-10 max-sm:px-4 py-4 text-white rounded-standart my-32'
	// 	>
	// 		<p>#Відмітка</p>
	// 		<p className='mt-10 font-bold text-xl uppercase'>
	// 			ВІДМІЧ НАШ
	// 			<br /> САЙТ В ІСТОРІЇ І ОТРИМАЙ ЧАШКУ КАВИ☕️
	// 		</p>
	// 	</div>
	// );
};

export default Mark;
